.App {
  margin: auto;
  padding: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .message-list {
    width: auto;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10% !important;
  }

  .message-input {
    max-width: 100% !important;
    margin-bottom: auto !important;
  }

  .App {
    width: 50%;
  }
}

.message-input {
  max-width: 60% ;
  margin-bottom: 1em;
}

.message-list {
  background: url("../public/cats.jpg") center repeat !important;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1%;
  font-size: 1.1em;
}


.beige .cs-message__sender-name {
  color: #a4a497 !important;
}
.red .cs-message__sender-name {
    color: #ec0b0b !important;
 }
.grey .cs-message__sender-name {
    color: #808080 !important;
 }
.blue .cs-message__sender-name {
    color: #2b26ea !important;
 }
.lime .cs-message__sender-name {
    color: #09a609 !important;
 }
.yellow .cs-message__sender-name {
    color: #a6ab04 !important;
 }
.cyan .cs-message__sender-name {
    color: #0d7979 !important;
 }
.fuchsia .cs-message__sender-name {
    color:#f0f !important;
 }
.silver .cs-message__sender-name {
    color: #868585 !important;
 }
.purple .cs-message__sender-name {
    color:#800080 !important;
 }
.green .cs-message__sender-name {
    color:#008000 !important;
 }
.orange .cs-message__sender-name {
    color:#FF7F50 !important;
 }
.pink .cs-message__sender-name {
    color: #FF69B4 !important;
 }
.black .cs-message__sender-name {
    color: #000 !important;
 }

.beige {
  color: #a4a497 !important;
}
.red  {
    color: #ec0b0b !important;
 }
.grey {
    color: #808080 !important;
 }
.blue {
    color: #2b26ea !important;
 }
.lime {
    color: #09a609 !important;
 }
.yellow {
    color: #a6ab04 !important;
 }
.cyan {
    color: #0d7979 !important;
 }
.fuchsia {
    color:#f0f !important;
 }
.silver {
    color: #868585 !important;
 }
.purple {
    color:#800080 !important;
 }
.green {
    color:#008000 !important;
 }
.orange {
    color:#FF7F50 !important;
 }
.pink {
    color: #FF69B4 !important;
 }
.black {
    color: #000 !important;
 }

.tng-logo-mobile {
    display: none;
}

.tng-logo-web {
    display: block;
}

.app-main-container {
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .tng-logo-mobile {
        display: block;
    }

    .tng-logo-web {
        display: none;
    }

    .app-main-container {
        border-radius: 0;
    }
}
